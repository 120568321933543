const getSites = async () => {
  let result = null
  try {
    result = await window.axios.get('/v1/sites/portfolio')
    let list = result.data.data;
    return list;
  } catch (err) {
    console.error(err);
    return Promise.reject(err)
  }
};

const getEgaugesForSite = async (siteId) => {
  let result = null
  try {
    result = await window.axios.get(`/v1/egaugemonitor/egauges/${siteId}`)
    let list = result.data.data;
    return list;
  } catch (err) {
    console.error(err);
    return Promise.reject(err)
  }
};

const getEgaugeById = async (id) => {
  let result = null
  try {
    result = await window.axios.get(`/v1/egaugemonitor/egauge/${id}`)
    let list = result.data.data;
    return list;
  } catch (err) {
    console.error(err);
    return Promise.reject(err)
  }
};

const createEgauge = async (meter) => {
  try {
    await window.axios.post('/v1/egaugemonitor/create', meter)
  } catch (err) {
    console.error(err);
    return Promise.reject(err)
  }
}

const deleteEgauge = async (egauge) => {
  let result = null;
  var config = {
    data: { 
      siteId: egauge.siteId,
      monitorId: egauge.monitorId
    }
  };
  try {
    result = await window.axios.delete(`/v1/egaugemonitor/delete`, config);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

const updateEgauge = async (meter) => {
  let result = null;
  try {
    result = await window.axios.put(`/v1/egaugemonitor/update`, meter);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

const getRegistersForEgauge = async (egaugeId) => {
  let result = null
  try {
    result = await window.axios.get(`/v1/register/monitor/${egaugeId}`)
    let list = result.data.data;
    return list;
  } catch (err) {
    console.error(err);
    return Promise.reject(err)
  }
};

const getRegisterById = async (registerId) => {
  let result = null
  try {
    result = await window.axios.get(`/v1/egauge/register/edit/${registerId}`)
    let list = result.data.data;
    return list;
  } catch (err) {
    console.error(err);
    return Promise.reject(err)
  }
};

const createRegister = async (register) => {
  try {
    console.log(register)
    await window.axios.post('/v1/egaugeregister/create', register)
  } catch (err) {
    console.error(err);
    return Promise.reject(err)
  }
};

const updateRegister = async (register) => {
  try {
    await window.axios.put(`/v1/egaugeregister/update`, register);

  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

const deleteRegister = async (register) => {
  try {
    let deleteRegisterResourceModel = {
      siteId: register.siteId,
      monitorId: register.monitorId,
      registerId: register.registerId
    }

    let config = {
      data: deleteRegisterResourceModel
    }

    await window.axios.delete(`/v1/egaugeregister/delete`, config);
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

const getRegisterUnitOfMeasures = async () => {
  let result = null
  const deviceSubTypeId = 2;  //devicesubtype.egauge in api
  try {
    result = await window.axios.get(`/v1/registerunitofmeasure/${deviceSubTypeId}`)
    let list = result.data.data;
    return list;
  } catch (err) {
    console.error(err);
    return Promise.reject(err)
  }
};

const getResourceTypes = async () => {
  let result = null
  try {
    result = await window.axios.get('/v1/ResourceType')
    let list = result.data.data;
    return list;
  } catch (err) {
    console.error(err);
    return Promise.reject(err)
  }
};

const getUnitOfMeasures = async (resourceTypeId) => {
  let result = null
  try {
    result = await window.axios.get(`/v1/UnitOfMeasure/${resourceTypeId}`)
    let list = result.data.data;
    return list;
  } catch (err) {
    console.error(err);
    return Promise.reject(err)
  }
};

const getEndUseUnitOfMeasures = async () => {
  //let result = null
  try {
    // result = await window.axios.get(`/v1/UnitOfMeasure/${resourceTypeId}`)
    // let list = result.data.data;
    let list = [{
      endUseUnitOfMeasureId: 1,
      endUseUnitOfMeasureName: "amp(s)",
      endUseUnitOfMeasureSymbol: "A"
    }, {
      endUseUnitOfMeasureId: 4,
      endUseUnitOfMeasureName: "kilowatt-hour(s)",
      endUseUnitOfMeasureSymbol: "kWh"
    }]
    return list;
  } catch (err) {
    console.error(err);
    return Promise.reject(err)
  }
};

const getPhases = async () => {
  //let result = null
  try {
    // result = await window.axios.get(`/v1/UnitOfMeasure/${resourceTypeId}`)
    // let list = result.data.data;
    let list = [
      { phaseId: 1, name: "SinglePhase" },
      { phaseId: 2, name: "ThreePhase" }
    ]
    return list;
  } catch (err) {
    console.error(err);
    return Promise.reject(err)
  }
};

const getEquipment = async (siteId) => {
  let result = null
  try {
    result = await window.axios.get(`/v1/Equipment/site/${siteId}`)
    let list = result.data.data;
    return list;
  } catch (err) {
    console.error(err);
    return Promise.reject(err)
  }
};

//TODO: we will need equipmentEndUseTypes or some such after redesign.  temporary
const getEndUseTypes = async (resourceTypeId) => {
  let result = null
  try {
    result = await window.axios.get(`/v1/endusetype/${resourceTypeId}`)
    let list = result.data.data;
    return list;
  } catch (err) {
    console.error(err);
    return Promise.reject(err)
  }
};


export default {
  getSites,
  getEgaugesForSite,
  getEgaugeById,
  createEgauge,
  deleteEgauge,
  updateEgauge,
  getRegistersForEgauge,
  getRegisterById,
  createRegister,
  deleteRegister,
  updateRegister,
  getRegisterUnitOfMeasures,
  getResourceTypes,
  getUnitOfMeasures,
  getPhases,
  getEquipment,
  getEndUseUnitOfMeasures,
  getEndUseTypes

}
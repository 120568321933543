<template>
  <v-container grid-list-xl>
    <p class="headline pt-3">Create Egauge</p>
    <validation-observer ref="obs" v-slot="{ invalid }">
      <v-form @submit.prevent="submit" v-model="valid">
        <v-sheet elevation="2" class="pa-4">
          <v-row>
            <v-col sm="6">
              <validated-text-field
                v-model="meter.deviceName"
                label="Name"
                rules="required"
              ></validated-text-field
            ></v-col>

            <v-col sm="6">
              <validated-text-field
                v-model="meter.friendlyName"
                label="Display Name"
                rules="required"
              ></validated-text-field
            ></v-col>
          </v-row>

          <v-row>
            <v-col sm="6" class="mt-3">
              <site-location-field :siteId="siteId" @sitelocationselected="siteLocationSelected" ref="siteLocation"></site-location-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-btn type="submit" color="primary" :disabled="!isValidMeter">Save</v-btn>
              <v-btn @click="handleCancelCrud" class="ml-5">Cancel</v-btn>
            </v-col>
          </v-row>          
        </v-sheet>

        <v-row justify="center">
            <stepper-validate 
            :invalid="invalid"
            :meterName="meter.deviceName"
            :friendlyName="meter.friendlyName"
            :siteLocation="selectedSiteLocation"            
            @dismissdialog = "dismissDialog"
            @fixmeter = "fixMeterErrors"
            ref="stepper"></stepper-validate>
        </v-row>
      </v-form>
      </validation-observer>
    </v-container>
</template>

<script>
import goTo from "vuetify/es5/services/goto";
import { ValidationObserver } from "vee-validate";
import ValidatedTextField from "@/components/Fields/ValidatedTextField";
import SiteLocationField from "@/modules/site/site_location/_components/SiteLocation";
import StepperValidate from "./StepperValidate.vue"
import api from "./../_api";

export default {
  components: {
    "validated-text-field": ValidatedTextField,
    "validation-observer": ValidationObserver,
    "site-location-field": SiteLocationField,
    "stepper-validate": StepperValidate
  },

  data() {
    return {
      valid: true,
      meter: {
        siteId: this.$route.params.id,
        deviceName: null,
        friendlyName: null,
        siteLocationId: null,
      },
      deviceName: null,
      friendlyName: null,
      siteId: null,
      siteLocations: [],
      selectedSiteLocation: null,
      isValidMeter: true,
    };
  },

  beforeCreate() {
  },

  async created() {
    this.siteId = this.$route.params.id;
    this.meter = {
        siteId: this.$route.params.id,
        deviceName: null,
        friendlyName: null,
        siteLocationId: null,
    }
  },

  computed: {
  },

  methods: {

    async submit() {
      let valid = await this.$refs.obs.validate();
      if (valid === false) {
        return;
      }
      this.$refs.stepper.validateMeter();
    },

    siteLocationSelected(selectedSiteLocation) {      
      this.selectedSiteLocation = selectedSiteLocation;      
    },

    fixMeterErrors(item) {
      switch (item) {
        case 2:
          this.$nextTick().then(() => {
            this.$refs.siteLocation.$refs.siteLocation.focus();
          });
          break;
        default:
          break;
      }
    },

    async dismissDialog(isValid) {
      this.isValidMeter = isValid;
      if (isValid) {
        goTo(0); //scroll up
        this.loading = true;
        try {
          this.meter.siteLocationId = this.selectedSiteLocation?.id;
          await api.createEgauge(this.meter);
          this.$router.push({ name: "SubMeterEgaugeIndex" });
        } catch (error) {
          console.log(error);
        }
        this.loading = false;
      }
    },

    handleCancelCrud() {
      this.$router.push({name: "SubMeterEgaugeIndex"});
    }

  },
};
</script>